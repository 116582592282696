import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import './AnalyticsView.css';

//more darker and grey colors
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF5733', '#C70039', '#900C3F', '#581845'];
const AnalyticsView = ({ monthlyData, categoryData }) => {
    return (
        <div className="analytics-subcontainer">
            <div className="analytics-charts">
                {/* Monthly Inventory Volume */}
                <div className="analytics-chart line-chart">
                    <h3>Monthly Inventory Volume</h3>
                    <ResponsiveContainer width="100%" height={250}>
                        <LineChart data={monthlyData}>
                            <XAxis dataKey="name" angle={-30} textAnchor='end' height={60} />
                            <YAxis />
                            <Tooltip />
                            <CartesianGrid strokeDasharray="5 5" />
                            <Line type="monotone" dataKey="volume" stroke="#8884d8" strokeWidth={2} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                {/* Inventory by Category */}
                <div className="analytics-chart pie-chart">
                    <h3>Inventory by Category</h3>
                    <ResponsiveContainer width="100%" height={250}>
                        <PieChart>
                            <Pie
                                data={categoryData}
                                cx="40%"
                                cy="50%"
                                outerRadius={100}
                                innerRadius={60}
                                label
                                dataKey="value"
                            >
                                {categoryData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend align="right" verticalAlign="middle" layout="vertical" />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsView;
